import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
/*import { browser } from '$app/environment';

// Helper function to detect the user's language in the browser
// TODO make this better together with the language switcher
function detectUserLanguage(): string {
	if (browser) {
		// Use cookie if available
		const cookieLang = document.cookie.split('; ').find((row) => row.startsWith('lang='));
		if (cookieLang) {
			return cookieLang.split('=')[1];
		}

		// Use browser's language
		return navigator.language.split('-')[0];
	}

	// Fallback to default language
	return 'de';
}
const lang = detectUserLanguage();*/

// This hook is called for every request on the client side

// configure Sentry (client side)
let enableSentry = false;
if (env.PUBLIC_SENTRY_ENVIRONMENT === 'production' || env.PUBLIC_SENTRY_ENVIRONMENT === 'staging') {
	enableSentry = true;
}
Sentry.init({
	enabled: enableSentry,
	environment: env.PUBLIC_SENTRY_ENVIRONMENT,
	dsn: 'https://a4f758b45d1b281d17ecf48637849bcd@o4505647075229696.ingest.sentry.io/4505647079555072',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0

	// TODO Handle sentry better, see https://kit.svelte.dev/docs/hooks

	// If you don't want to use Session Replay, just remove the line below:
	/*integrations: [
		Sentry.replayIntegration(),
		Sentry.feedbackIntegration({
			showBranding: false,
			triggerLabel: lang === 'de' ? 'Fehler melden' : 'Report error',
			formTitle: lang === 'de' ? 'Fehler melden' : 'Report error',
			submitButtonLabel: lang === 'de' ? 'Fehlermeldung absenden' : 'Submit error report',
			cancelButtonLabel: lang === 'de' ? 'Abbrechen' : 'Cancel',
			confirmButtonLabel: lang === 'de' ? 'Bestätigen' : 'Confirm',
			addScreenshotButtonLabel: lang === 'de' ? 'Screenshot hinzufügen' : 'Add screenshot',
			removeScreenshotButtonLabel: lang === 'de' ? 'Screenshot entfernen' : 'Remove screenshot',
			namePlaceholder: lang === 'de' ? 'Name' : 'Name',
			emailPlaceholder:
				lang === 'de' ? 'vorname.nachname@tu-dortmund.de' : 'firstname.lastname@tu-dortmund.de',
			isRequiredLabel: lang === 'de' ? '(Pflichtfeld)' : '(required)',
			messageLabel: lang === 'de' ? 'Beschreibung des Fehlers' : 'Description of the error',
			messagePlaceholder:
				lang === 'de'
					? 'Bitte beschreiben Sie den Fehler so genau wie möglich.'
					: 'Please describe the error as precisely as possible.',
			successMessageText:
				lang === 'de' ? 'Vielen Dank für Ihre Meldung!' : 'Thank you for your report!'
		})
	]*/
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
